var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.certificate.certificate ? _c('b-modal', {
    attrs: {
      "size": "lg",
      "no-close-on-backdrop": "",
      "no-close-on-esc": "",
      "hide-header": "",
      "hide-footer": ""
    },
    model: {
      value: _vm.showModal,
      callback: function callback($$v) {
        _vm.showModal = $$v;
      },
      expression: "showModal"
    }
  }, [_c('div', {
    staticClass: "modal-header"
  }, [_c('h5', {
    staticClass: "modal-title"
  }, [_vm._v(_vm._s("Proses Endorsement ".concat(_vm.certificate.certificate.name, " (").concat(_vm.certificate.vehicle.name, ")")))]), _c('button', {
    staticClass: "close",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('span', {
    attrs: {
      "aria-hidden": "true"
    }
  }, [_vm._v("×")])])]), _c('DetailCertificate', {
    attrs: {
      "certificate": _vm.certificate
    }
  }), _c('EndorsementCertificate', {
    attrs: {
      "certificate": _vm.certificate
    }
  }), _c('br'), _c('div', {
    staticClass: "mt-3 mb-2"
  }, [_c('h4', [_vm._v("Input Endorsement Process")]), _c('hr')]), _vm.certificate.certificate.certificateType !== 'PERMANENT' ? _c('b-row', [_c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Endors. Process",
      "label-for": "name_certificate"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "endorseNumber",
      "options": _vm.certificate.endorsement,
      "selectable": function selectable(option) {
        return option.mustProcess;
      }
    },
    on: {
      "input": _vm.onChangeEndorseNumber
    },
    model: {
      value: _vm.form_endorsement.selected_endorsement,
      callback: function callback($$v) {
        _vm.$set(_vm.form_endorsement, "selected_endorsement", $$v);
      },
      expression: "form_endorsement.selected_endorsement"
    }
  })], 1)], 1)], 1) : _vm._e(), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Endorse Date*",
      "label-for": "name_certificate"
    }
  }, [_c('date-picker', {
    attrs: {
      "value-type": "YYYY-MM-DD",
      "type": "date",
      "placeholder": "Select date",
      "format": "YYYY-MM-DD"
    },
    model: {
      value: _vm.form_endorsement.processDate,
      callback: function callback($$v) {
        _vm.$set(_vm.form_endorsement, "processDate", $$v);
      },
      expression: "form_endorsement.processDate"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "File",
      "label-for": "name_certificate"
    }
  }, [_c('b-form-file', {
    attrs: {
      "placeholder": "Choose a file",
      "id": "customFile",
      "accept": "application/pdf"
    },
    on: {
      "change": function change($event) {
        return _vm.uploadFile($event);
      }
    }
  }), _vm.loading_upload_file ? _c('p', [_vm._v("Uploading...")]) : _vm._e()], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Notes",
      "label-for": "name_certificate"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "id": "description",
      "rows": "3",
      "placeholder": "Type Notes"
    },
    model: {
      value: _vm.form_endorsement.notes,
      callback: function callback($$v) {
        _vm.$set(_vm.form_endorsement, "notes", $$v);
      },
      expression: "form_endorsement.notes"
    }
  })], 1)], 1)], 1), _c('hr'), _c('b-form-group', {
    staticClass: "text-right"
  }, [_c('b-button', {
    attrs: {
      "type": "button",
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.doEndorsement(_vm.certificate);
      }
    }
  }, [!_vm.loading_endorsement ? _c('span', [_vm._v(" Do Endorsement ")]) : [_c('span', {
    staticClass: "spinner-border spinner-border-sm",
    attrs: {
      "role": "status",
      "aria-hidden": "true"
    }
  }), _vm._v(" Processing... ")]], 2), _c('b-button', {
    staticClass: "iq-bg-danger ml-3",
    attrs: {
      "type": "button",
      "variant": "danger"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v("Cancel")])], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }