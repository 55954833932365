<template>
  <b-modal
    v-model="showModal"
    v-if="certificate.certificate"
    size="lg"
    no-close-on-backdrop
    no-close-on-esc
    hide-header
    hide-footer
  >
    <div class="modal-header">
      <h5 class="modal-title">{{
        `Proses Endorsement ${certificate.certificate.name} (${certificate.vehicle.name})`
      }}</h5>
      <button type="button" class="close" @click="$emit('close')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <DetailCertificate :certificate="certificate" />

    <EndorsementCertificate :certificate="certificate" />

<!--    <b-col md="12" class="mt-3 mb-2">-->
<!--      <h4>Previous Endorsement Process</h4><hr />-->
<!--    </b-col>-->
<!--    <b-col md="12">-->
<!--      <table class="table table-striped">-->
<!--        <thead>-->
<!--          <tr>-->
<!--            <th width="15%">Endors. Number</th>-->
<!--            <th width="25%">Process Date</th>-->
<!--            <th width="25%">Notes</th>-->
<!--            <th width="15%">File</th>-->
<!--          </tr>-->
<!--        </thead>-->
<!--        <tbody>-->
<!--          <template-->
<!--            v-if="-->
<!--              certificate.endorsement.length > 0 &&-->
<!--              anyPreviousEndorsement(certificate.endorsement)-->
<!--            "-->
<!--          >-->
<!--            <template-->
<!--              v-for="(endorsement, index_endorsement) in certificate.endorsement"-->
<!--            >-->
<!--              <tr-->
<!--                :key="`p-endorse-${index_endorsement}`"-->
<!--                v-if="endorsement.processDate != null"-->
<!--              >-->
<!--                <td>{{ endorsement.endorseNumber }}</td>-->
<!--                <td>{{ endorsement.endEndorseDate }}</td>-->
<!--                <td>{{ endorsement.notes ? endorsement.notes : '-' }}</td>-->
<!--                <td>-->
<!--                  <template v-if="endorsement.file">-->
<!--                    <a :href="endorsement.file.url" target="_blank"-->
<!--                      v-b-tooltip.hover-->
<!--                      :title="certificate.file.fileName">-->
<!--                      <i class="fa fa-download"></i>-->
<!--                    </a>-->
<!--                  </template>-->
<!--                  <span v-else>-</span>-->
<!--                </td>-->
<!--              </tr>-->
<!--            </template>-->
<!--          </template>-->
<!--          <tr v-else>-->
<!--            <td colspan="4" class="text-center py-4"-->
<!--              >No previous endorsement data found.</td-->
<!--            >-->
<!--          </tr>-->
<!--        </tbody>-->
<!--      </table>-->
<!--    </b-col>-->

    <br />
    <div class="mt-3 mb-2">
      <h4>Input Endorsement Process</h4>
      <hr />
    </div>

    <b-row v-if="certificate.certificate.certificateType !== 'PERMANENT'">
      <b-col md="4">
        <b-form-group
          label="Endors. Process"
          label-for="name_certificate"
        >
          <v-select
            label="endorseNumber"
            :options="certificate.endorsement"
            :selectable="(option) => option.mustProcess"
            v-model="form_endorsement.selected_endorsement"
            @input="onChangeEndorseNumber"
          >
          </v-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <b-form-group
          label="Endorse Date*"
          label-for="name_certificate"
        >
          <date-picker v-model="form_endorsement.processDate" value-type="YYYY-MM-DD" type="date" placeholder="Select date" format="YYYY-MM-DD"></date-picker>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group label="File" label-for="name_certificate">
          <b-form-file
            placeholder="Choose a file"
            id="customFile"
            accept="application/pdf"
            @change="uploadFile($event)"
          ></b-form-file>

          <p v-if="loading_upload_file">Uploading...</p>
        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group label="Notes" label-for="name_certificate">
          <b-form-textarea
            id="description"
            rows="3"
            placeholder="Type Notes"
            v-model="form_endorsement.notes"
          ></b-form-textarea>
        </b-form-group>
      </b-col>
    </b-row>

    <hr>
    <b-form-group class="text-right">
      <b-button type="button" variant="primary" @click="doEndorsement(certificate)">
        <span v-if="!loading_endorsement">
          Do Endorsement
        </span>
        <template v-else>
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          Processing...
        </template>
      </b-button>
      <b-button
        type="button"
        variant="danger"
        class="iq-bg-danger ml-3"
        @click="$emit('close')"
        >Cancel</b-button
      >
    </b-form-group>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex'
import { certificatesActions, filesActions } from '@src/Utils/helper'
import moment from 'moment'

var year  = new Date().getFullYear();
var month = new Date().getMonth();
var day   = new Date().getDate();
var maxProcessDate  = new Date(year + 1, month, day);

var d = new Date();
d.setMonth(d.getMonth() - 6);
var minYear  = new Date(d).getFullYear();
var minMonth = new Date(d).getMonth();
var minDay   = new Date(d).getDate();
const minProcessDate = new Date(minYear, minMonth, minDay);

export default {
  name: 'ModalEndorsement',
  components: {
    DetailCertificate: () =>
      import('@components/certificate/DetailCertificate'),
    EndorsementCertificate: () =>
      import('@components/certificate/EndorsementCertificate'),
  },
  computed: {
    ...mapState('certificates', {
      modal_endorsement: (state) => state.modal_endorsement,
    }),
    showModal() {
      return this.modal_endorsement.show
    },
    certificate() {
      return this.modal_endorsement.certificate
    }
  },
  watch: {
    showModal() {
      if (this.showModal) {
        this.resetForm()
        this.mustProcessEndorseDate()
      }
    }
  },
  data() {
    return {
      loading_upload_file: false,
      loading_endorsement: false,
      form_endorsement: {
        selected_endorsement: {},
        processDate: '',
        notes: '',
        file: '',
      },
      maxProcessDate,
      minProcessDate
    }
  },
  methods: {
    ...certificatesActions,
    ...filesActions,
    async uploadFile(event) {
      this.loading_upload_file = true

      const files = [...event.target.files]
      let file = files[0]

      let formData = new FormData()
      formData.append('file', file)

      let res = await this.upload(formData)
      if (res.status == 'success') {
        this.loading_upload_file = false
        this.form_endorsement.file = res.data.fileName
      } else {
        this.loading_upload_file = false
        if (res.data.message != null)
          this.$swal('Error', res.data.message, 'error')
        else
          this.$swal(
            'Error',
            'Something wrong with the server. Please try again later.',
            'error'
          )
      }
    },
    async doEndorsement(certificate) {
      this.loading_endorsement = true

      let processDate = moment(this.form_endorsement.processDate).format('DD-MM-YYYY')

      let payload = {
        notes: this.form_endorsement.notes,
        file: this.form_endorsement.file,
        processDate,
      }

      let form_has_empty_value = false
      for (var key in payload) {
        if (payload[key] === '') form_has_empty_value = true
        else if (Array.isArray(payload[key]) && !payload[key].length)
          form_has_empty_value = true
      }

      if (form_has_empty_value || Object.keys(payload).length == 0)
        this.$swal(
          'Empty form!',
          'Please check again and fill all the forms.',
          'error'
        )
      else {
        let res = await this.endorsementCertificateVehicle({
          id: certificate.id,
          id_endorsement: this.form_endorsement.selected_endorsement.id,
          data: payload,
        })
        if (res.status == 'success') {
          this.form_endorsement = {
            selected_endorsement: {},
            processDate: '',
            notes: '',
            file: '',
          }
          this.$emit('refresh')
          this.$swal(
            `Endorsement Process Success!`,
            `Certificate ${certificate.certificate.name} endorsed successfully.`,
            'success'
          )
          this.$emit('close')
        } else {
          if (res.data.message != null)
            this.$swal('Error', res.data.message, 'error')
          else
            this.$swal(
              'Error',
              'Something wrong with the server. Please try again later.',
              'error'
            )
        }
        this.loading_endorsement = false
      }
    },
    onChangeEndorseNumber() {
      if (this.form_endorsement.selected_endorsement) {
        this.form_endorsement.processDate = this.$options.filters.parseDate(
          this.form_endorsement.selected_endorsement.endorseDate,
          'YYYY-MM-DD'
        )
      }
    },
    anyPreviousEndorsement(endorsements) {
      const processedEndorsements = endorsements.find(end => end.processDate)

      if (processedEndorsements) {
        return true
      }
      return false
    },
    mustProcessEndorseDate() {
      const endorsements = this.certificate.endorsement
      const processedEndorsements = endorsements.find(end => end.mustProcess)

      if (processedEndorsements) {
        const endorseDate = this.$options.filters.parseDate(processedEndorsements.endEndorseDate)
        this.form_endorsement.processDate = moment(endorseDate).format('YYYY-MM-DD')
        this.form_endorsement.selected_endorsement = processedEndorsements

        var year  = new Date(endorseDate).getFullYear();
        var month = new Date(endorseDate).getMonth();
        var day   = new Date(endorseDate).getDate();
        this.maxProcessDate  = new Date(year + 1, month, day);

        var d = new Date(endorseDate);
        d.setMonth(d.getMonth() - 6);
        var minYear  = new Date(d).getFullYear();
        var minMonth = new Date(d).getMonth();
        var minDay   = new Date(d).getDate();
        this.minProcessDate = new Date(minYear, minMonth, minDay);
      }
    },
    resetForm() {
      this.form_endorsement = {
        selected_endorsement: {},
        processDate: '',
        notes: '',
        file: '',
      }
    }
  },
}
</script>
